<template> 
  <nav class="navbar navbar-expand-lg"> 
    <div class="container justify-content-center align-items-center">
      <div class="text-center" id="navbarNavAltMarkup">
        <div class="navbar-nav mx-auto">
          <router-link class="navItem" to="/">About</router-link>
          <router-link class="navItem" to="/certificates">Certificates</router-link>
          <router-link class="navItem" to="/projects">Projects</router-link>
          <router-link class="navItem" to="/contact">Contact</router-link>
        </div> 
      </div> 
    </div> 
  </nav>

  
</template>