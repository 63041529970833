<template>
  <div class="container text-center footerContainer justify-content-center align-items-center">
    <div class="row justify-content-center align-items-center mx-auto mb-2 mt-2">
      <div class="col d-flex justify-content-center">
        <a target="_blank" href="https://github.com/TheRealGameZ"><i class="bi bi-github m-2"></i></a>
        <a target="_blank" href="mailto:Andreas_Richter22@gmx.net"><i class="bi bi-envelope m-2"></i></a>
        <a target="_blank" href="https://www.linkedin.com/in/andreas-richter-8b1688246/"><i class="bi bi-linkedin m-2"></i></a>
      </div>
    </div>
    <!-- Datenschutz & Impressum Row -->
    <div class="row justify-content-center mt-2">
      <div class="col d-flex justify-content-center">
        <router-link to="/datenschutz" class="mx-2"><a href="">Datenschutzerklärung</a></router-link>
        <router-link to="/impressum" class="mx-2"><a href="">Impressum</a></router-link>
      </div>
    </div>
  </div>
</template>
